<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Daftar Kategori Topik</span>
        <div class="pull-right">
          <router-link to="/forum/category/create">
            <button class="btn btn-with-addon mr-auto btn-primary">
                  <span class="btn-addon"
                  ><i class="btn-addon-icon fe fe-plus-circle"></i
                  ></span>
              Tambah
            </button>
          </router-link>
        </div>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Semua Kategori</h5>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <a-input-search placeholder="Temukan kategori" enter-button @search="onSearch"/>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="data"
                  :pagination="pagination"
                  :loading="loading"
                  @change="handleTableChange"
              >

                <span slot="image" slot-scope="image">
                  <a :href="image" target="_blank" v-if="image">
                  <img :src="image" class="width-64"/>
                  </a>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
                <span slot="deskripsi" slot-scope="deskripsi">
                  <p v-if="(deskripsi != 'undefined' && deskripsi != null)">
                    {{deskripsi}}
                  </p>
                  <p v-else>-</p>
                </span>
                <span slot="action" slot-scope="text">
                  <router-link :to="{ path: '/forum/category/update/' + text }">
                    <button class="btn btn-sm btn-light mr-2">
                      <i class="fe fe-edit mr-2"/>
                      Ubah
                    </button>
                  </router-link>
                  <a-tooltip placement="topLeft" title="Hapus">
                    <button
                        class="btn btn-sm btn-danger"
                        @click="modalDeleteContent(text)"
                    >
                      <small>
                        <i class="fe fe-trash"/>
                      </small>
                    </button>
                  </a-tooltip>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {deleteCategoryForum, getListCategoryForum} from "@/api/forum.api";

const columns = [
  {
    title: "Icon",
    dataIndex: "icon",
    scopedSlots: {customRender: 'image'}
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    scopedSlots: {customRender: 'deskripsi'}
  },
  {
    title: "Aksi",
    dataIndex: "id",
    scopedSlots: {customRender: "action"}
  }
];

export default {
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      loading: false,
      columns,
      filterKey: '',
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleTableChange(pagination) {
      const pager = {...this.pagination};
      pager.current = pagination.current;

      this.pagination = pager;
      this.fetchData({
        page: pager.current,
        limit: pager.pageSize,
        filter: this.filterKey,
      });
    },
    async fetchData(params = {}) {
      // console.log(params)
      try {
        this.loading = true;
        let response = await getListCategoryForum(params);
        if (response.status == 200) {
          this.data = response.data.results;
          const pagination = {...this.pagination};
          pagination.total = response.data.paging.total;
          this.loading = false;
          this.pagination = pagination;
        }
      } catch (error) {/**/
      }
    },
    async onSearch(value) {
      await this.fetchData({search: value})
    },
    modalDeleteContent(_id_content) {
      this.$modal.confirm({
        title: "Hapus kategori",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        content: "Apakah Anda yakin untuk menghapus Kategori ini?",
        onOk: () => {
          this.deleteContents(_id_content);
        },
        onCancel() {
        }
      });
    },
    async deleteContents(id_content) {
      try {
        this.$nprogress.start();
        await deleteCategoryForum(id_content);
        this.$notification["success"]({
          message: "Sukses",
          description: "Kategori berhasil dihapus"
        });
        await this.fetchData();
      } catch (error) {
        // console.log(error);
      }
      this.$nprogress.done();
    }
  }
};
</script>
<style scoped>
.width-64 {
  max-width: 85px;
  max-height: fit-content;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
